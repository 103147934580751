Sofoot.modules.top = (() => {
    let top, cpt;
    return {
        activerAnalytics() {
            document.addEventListener('top-changer-slide', (e) => {
                if (cpt > 0) {
                    let url = '/'+location.pathname.substr(1) + document.location.hash;
                    let titre = e.data.slide.dataset.titre + ' - ' + e.data.top.dataset.titre;
                    Sofoot.modules.analytics.hit({url, titre})
                }
                cpt++;
            })
        },
        start() {
            top = document.querySelector('[data-zone="top"]');
            if (top) {
                cpt = 0;
                this.activerAnalytics()
            }
        }
    }

})();