Sofoot.modules.sondages = (() => {

    function addVote(data) {
        // console.log("[SONDAGES] Début de la fonction addVote", { data });
        const userId = Sofoot.modules.compte.getCurentUserId();
        // console.log("[SONDAGES] ID utilisateur récupéré :", userId);

        const bodyData = {
            voter_id: userId,
            reponse: data.choixId
        };

        // console.log("[SONDAGES] Envoi de la requête pour ajouter un vote avec les données :", bodyData);
        return fetch(URL_API + 'sondages/' + data.sondageId + '/reponse', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(bodyData)
        })
            .then(response => {
                // console.log("[SONDAGES] Réponse reçue :", response);
                return response.json();
            })
            .then(resultats => {
                // console.log("[SONDAGES] Résultats du vote :", resultats);
                showResults(data.sondageId, resultats);

                try {
                    // console.log("[SONDAGES] Envoi d'un événement Fathom.");
                    fathom.trackEvent('Vote sur le sondage ' + data.sondageId + ' "' + data.question + '"');
                } catch (e) {
                    console.warn("[SONDAGES] Erreur lors de l'envoi d'un événement Fathom :", e);
                }
            })
            .catch(error => {
                console.error("[SONDAGES] Erreur lors de l'envoi du vote :", error);
            });
    }

    function showResults(sondageId, choix) {
        // console.log("[SONDAGES] Affichage des résultats pour le sondage :", { sondageId, choix });
        const payload = { sondageId: sondageId, resultats: { choix: choix } };
        emettreEvenement('voir-resultats-sondage', payload);
    }

    function userHasVoted(sondageId) {
        // console.log("[SONDAGES] Enregistrement du vote pour le sondage :", sondageId);
        if (sondageId) {
            localStorage.setItem('sondage-' + sondageId, 'true');
        }
    }

    function userCanVote(sondageId) {
        const hasVoted = localStorage.getItem('sondage-' + sondageId) == 'true';
        // console.log("[SONDAGES] Vérification si l'utilisateur a voté :", hasVoted);
        return !hasVoted;
    }

    function getResultats(sondageId) {
        // console.log("[SONDAGES] Récupération des résultats pour le sondage :", sondageId);
        const now = new Date();
        const formattedDate = now.getFullYear() +
            String(now.getMonth() + 1).padStart(2, '0') +
            String(now.getDate()).padStart(2, '0') +
            String(now.getHours()).padStart(2, '0') +
            String(now.getMinutes()).padStart(2, '0');
        const url = URL_API + 'sondages/' + sondageId + '/resultats?' + formattedDate;

        // console.log("[SONDAGES] URL construite pour la requête :", url);
        return fetch(url, { cache: 'no-store' })
            .then(response => {
                // console.log("[SONDAGES] Réponse reçue :", response);
                return response.json();
            })
            .catch(error => {
                console.error("[SONDAGES] Erreur lors de la récupération des résultats :", error);
                return null;
            });
    }

    function set() {
        // console.log("[SONDAGES] Initialisation du sondage.");
        const sondage = document.querySelector('.sondage');
        if (!sondage) {
            console.warn("[SONDAGES] Aucun élément sondage trouvé.");
            return Promise.resolve();
        }

        const sondageId = sondage.dataset.id;
        // console.log("[SONDAGES] ID du sondage trouvé :", sondageId);

        if (!userCanVote(sondageId)) {
            // console.log("[SONDAGES] L'utilisateur a déjà voté pour ce sondage.");

            return getResultats(sondageId)
                .then(resultats => {
                    if (resultats) {
                        // console.log("[SONDAGES] Résultats récupérés :", resultats);
                        showResults(sondageId, resultats);
                    } else {
                        console.warn("[SONDAGES] Aucun résultat disponible pour ce sondage.");
                    }
                });
        }

    }

    return {
        addVote,
        showResults,
        userHasVoted,
        userCanVote,
        getResultats,
        set,

        start() {
            // console.log("[SONDAGES] Chargement du module sondages.");
            const sondage = document.querySelector('.sondage');
            if (!sondage) {
                console.warn("[SONDAGES] Aucun élément sondage trouvé au chargement.");
                return;
            }

            set();
            document.addEventListener('vote-sondage', (e) => {
                // console.log("[SONDAGES] Événement 'vote-sondage' déclenché :", e);
                const data = e.data;
                if (!userCanVote(data.sondageId)) {
                    // console.log("[SONDAGES] L'utilisateur a déjà voté pour ce sondage.");
                    return;
                }

                addVote(data).then(() => {
                    if (!data.multi) {
                        // console.log("[SONDAGES] Enregistrement du vote unique pour ce sondage.");
                        userHasVoted(data.sondageId);
                    }
                });
            });
        }
    };
})();
