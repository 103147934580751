Sofoot.modules.preview = (() => {
    let Article, pid, loading;
    return {
        start() {
            if (document.body.dataset.preview) {
                pid = article?.pid || article?.id;
                if (document.location.hash == '#fenetre') {
                    document.body.dataset.preview = 'fenetre';
                }
                Article = document.querySelector('[data-zone="article"]');
                console.log('Preview activée');
                let bc = new BroadcastChannel('sofoot');
                // bc.postMessage({ name: 'preview-start', query: { id: pid } });
                bc.onmessage = (e) => {
                    if (e.data.name == 'update-preview') {
                        if (e.data.query.id == pid) {
                            // let url = document.location.href.split('#')[0];
                            if (!loading) {
                                console.log('update-preview');
                                loading = true;
                                _api.get(`/api/preview/${pid}`, { nonce: globals.nonce }).then(response => {
                                    loading = false;
                                    Article.querySelector('header').outerHTML = response.header;
                                    Article.querySelector('.content').outerHTML = response.content;
                                    
                                    // Article.scrollIntoView({ behavior: 'smooth' });
                                    Sofoot.modules.twitter.parseTweets(Article);
                                })
                            }
                        }
                    }
                }
            }

        }
    }
})();
