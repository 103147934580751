Sofoot.modules.breves = (() => {
    return {
        start() {
            document.addEventListener('charger-apercu-breve', (e) => {
                let id = e.data.id;
                let url = e.data.url;
                _api.get(url).then(response => {
                    let div = document.createElement('div');
                    div.innerHTML = response;
                    let content = div.querySelector('.colonnes-principales .colonne > article');
                    if(content) {
                        let text=[]
                        content.querySelectorAll('.chapo, .content > p').forEach(element => {
                            text.push('<p>'+element.innerText+'</p>');
                        })
                        text = text.join('');
                        console.log(id, url, text)
                        App.modules.breves.ouvrirApercu(id, url, text);

                    }
                });



            });
        }
    }
})();