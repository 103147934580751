Sofoot.modules.pagination = (() => {
    return {
        activerPagination() {
            let nav = document.querySelector('[data-pagination-js="true"]');
            if (!nav) return;
            
            nav.addEventListener('click', (e) => {
                let a = e.target.closest('a.btn');
                if (!a) return;
                e.preventDefault();
                this.chargerPage(a);
            });

        },
        chargerPage(a) {
            a.dataset.loading = 'true';
            fetch(a.href).then(response => response.text()).then(html => {
                let nav = a.closest('[data-pagination-js="true"]');
                let dom = document.implementation.createHTMLDocument().body;
                dom.innerHTML = html;
                let titre = dom.title || dom.querySelector('meta[name="title"]').getAttribute('content');
                simulerPageVue(a.href, titre);
                if (dom.querySelector('.liste-articles')) {
                    document.querySelector('.liste-articles').innerHTML += dom.querySelector('.liste-articles').innerHTML;
                    nav.innerHTML = dom.querySelector('[data-pagination-js="true"]').innerHTML;
                } else if (dom.querySelector('#cadre > .colonnes')) {
                    nav.remove();
                    let colonnes = dom.querySelector('#cadre > .colonnes');
                    document.querySelector('#cadre').appendChild(colonnes);
                    let pagination = dom.querySelector('[data-pagination-js="true"]');
                    document.querySelector('#cadre').appendChild(pagination);
                    this.activerPagination();
                }
                delete a.dataset.loading;
            });

        },
        start() {

            this.activerPagination();
        }
    }
})();