Sofoot.modules.twitter = (() => {
    let tweets, observer;

    return {
        parseTweets(element = null) {
            if (!element) element = document;

            element.querySelectorAll('.twitter-tweet').forEach(tweet => {
                let url = tweet.querySelector('a[href*="status/"]').href;
                if (!url) return;
                let id = url.split('?')[0].split('/status/')[1];
                if (!id) return;
                let div = document.createElement('div');
                div.setAttribute('data-tweet-id', id);
                tweet.replaceWith(div);
                twttr.widgets.createTweet(id, document.querySelector(`[data-tweet-id="${id}"]`), {
                    conversation: 'none', // or all
                    cards: 'visible', // or visible
                    // linkColor: '#cc0000', // default is blue
                    theme: App.modules.darkMode.getScheme(),
                })

            })
        },
        loadTweet(tweet) {
            console.log('loadTweet', tweet);
            observer.unobserve(tweet);
            tweet.setAttribute('class', 'twitter-tweet');
            script_load('https://platform.twitter.com/widgets.js');
        },
        lazyLoadTweets() {
            if ("IntersectionObserver" in window) {
                observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        const { isIntersecting, intersectionRatio } = entry;
                        if (isIntersecting) {
                            Sofoot.modules.twitter.loadTweet(entry.target)
                        }
                    });
                });

                tweets.forEach(tweet => observer.observe(tweet));
            } else {
                tweets.forEach(tweet => {
                    tweet.setAttribute('class', 'twitter-tweet');
                });
                script_load('https://platform.twitter.com/widgets.js');
            }
        },
        start() {
            let article = document.querySelector('article[data-zone="article"]');
            if (article) {
                console.log('twitter')
                tweets = article.querySelectorAll('.twitter-tweet-lazy');
                this.lazyLoadTweets();
            }
        }
    }
})();
