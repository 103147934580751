Sofoot.modules.jaime = (() => {
	let Jaime;
	return {
		fetchJaime() {
			// un chargement est déjà en cours : on annule
			if (Jaime.dataset.chargement) return;

			// le champ de saisie de l'emoji custom à le focus : saisie en cours, on annule
			if (document.querySelector(':focus')?.closest('[type="emoji"]')) return;

			// début du chargement
			Jaime.dataset.chargement = true;
			api.get(`jaime/status/${article.id}`, { cache: false }).then(response => {
				emettreEvenement('update-jaime', { jaimes: response.jaimes, likes: response.likes, emojis: response.emojis });
			});
		},
		activerJaimes() {
			setIntervalNow(() => this.fetchJaime(), UNE_MINUTE);
		},
		setEvents() {
			// Jaime.addEventListener('mousemove', () => {
			// 	attendre('fetchJaime', () => this.fetchJaime(), 1000);
			// });
			document.addEventListener('choix-jaime', e => {
				api.post(`jaime/${article.id}`, { emoji: e.data.emoji, user_id: Sofoot.modules.compte.getCurentUserId() }).then(response => {
					emettreEvenement('update-jaime', { jaimes: response.jaimes, likes: response.likes, emojis: response.emojis });
				});
			});
		},
		start() {
			Jaime = document.querySelector('.jaime')
			if (!Jaime) return;

			if (document.location.hash.includes('test-jaime')) {
				localStorage.setItem('test-jaime', true);
			}

			if (!localStorage.getItem('test-jaime')) {
				Jaime.setAttribute('style', 'display:none !important');
			}


			document.addEventListener('scroll-end', () => {
				this.activerJaimes();
				this.setEvents();
			}, { once: true });

		}
	}
})();