Sofoot.modules.storiesSlider = (() => {
    return {
        start() {
            document.addEventListener('story-video-play', e => {
                const post_url = e.data.post_url;
                fathom.trackEvent('Lecture ' + post_url);
            })

        }
    }
})();



