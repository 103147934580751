
Sofoot.modules.fiches = (() => {
    let filtres;
    return {
        filtrerFiche(cible) {
            let uri = [filtres.dataset.base];
            let selects = filtres.querySelectorAll('select');
            let select;
            for (select of selects) {
                uri.push(select.name);
                uri.push(select.value);
                if (cible == select) {
                    break;
                }
            }
            let url = uri.join('/');
            document.location.href = url;
        },
        activerFiltres() {
            filtres.querySelectorAll('select').forEach(select => select.addEventListener('change', e => {
                this.filtrerFiche(e.target);
            }));
        },
        start() {
            if (document.querySelector('[data-zone^="fiche-"]')) {
                if (filtres = document.querySelector('.filtres')) {
                    this.activerFiltres();
                }
            }
        }
    }
})();
