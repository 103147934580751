
Sofoot.modules.recherche = (() => {
    let termes, xhr;
    return {
        activerRecherche() {
            document.addEventListener('menu-slide-recherche', e => {
                termes = e.data.termes;
                if (xhr) {
                    xhr.abort();
                }
                xhr = _api.get('/api/recherche', { s: termes }).done(reponse => {
                    e.module.afficherResultats(reponse);
                })
                console.log(xhr)
            });
        },
        start() {
            this.activerRecherche();
        }
    }
})();
