/**
 * Lire et écrire un cookie
 */
Sofoot.modules.cookie = (() => {
    return {
        /**
         * Lire un cookie
         * @param {string} name Nom du cookie
         * @param {boolean} parse Parser en JSON la sortie
         * @returns {mixed}
         */
        lire(name, parse = false) {
            name += '=';
            for (var ca = document.cookie.split(/;\s*/), i = ca.length - 1; i >= 0; i--)
                if (!ca[i].indexOf(name)) {
                    let cookie = ca[i].replace(name, '');
                    cookie = decodeURIComponent(cookie);
                    if (cookie && parse) {
                        cookie = JSON.parse(cookie);
                    }
                    return cookie;
                }
        },
        nom() {
            return nom_cookie;
        }
    }
})();
