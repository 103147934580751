/**
 * Module pour gérer la connexion au compte membre
 */
Sofoot.modules.compte = (() => {
    let magicLogin = false;
    return {
        activerFormLogin() {
            let form = document.querySelector('form[data-name="login"]');

            if (form) {
                ['change', 'keyup'].forEach(evenement => {
                    form.querySelectorAll('[name="login[identifiant]"], [name="login[pass]"]').forEach(input => input.addEventListener(evenement, (e) => {
                        let identifiant = document.querySelector('[name="login[identifiant]"]').value;
                        console.log(identifiant)
                        let passInput = document.querySelector('[name="login[pass]"]');
                        if (identifiant.substr(0, 1) == '@') {
                            magicLogin = true;
                            passInput.removeAttribute('required')
                        } else {
                            magicLogin = false;
                            passInput.setAttribute('required', 'true')
                        }

                    }))
                });


                form.addEventListener('submit', function (e) {
                    e.preventDefault();

                    form.dataset.loading = true;
                    let formData = new FormData(form);
                    let params = {};
                    formData.forEach((value, key) => params[key] = value);
                    if (!params['login[pass]'] && magicLogin) {
                        params['login[pass]'] = Math.random();
                    }
                    _api.post('/api/login', params).then(response => {
                        if (response.message) {
                            App.modules.notifications.afficher({ type: 'success', contenu: response.message, transition: 'slide', close: false })
                        }
                        if (response['message-apres-redirection']) {
                            App.modules.notifications.stocker({ type: 'success', contenu: response['message-apres-redirection'], autoclose: true, transition: 'slide' })
                        }
                        if (response.cible) {
                            setTimeout(() => window.open(response.cible, '_self'), 1000);
                        }
                    }).fail(error => {
                        console.log(error);
                        if (error.responseJSON?.message) {
                            App.modules.notifications.afficher({ type: 'error', contenu: error.responseJSON.message, transition: 'slide' })
                        } else if (error.responseText) {
                            App.modules.notifications.afficher({ type: 'warning', contenu: error.responseText, transition: 'slide', autoclose: true })
                        }
                        delete form.dataset.loading;
                    })
                });
            }

        },
        gestionMotDePasse() {
            if (!document.querySelector('[href="#modifier-mot-de-passe"]')) return;
            document.querySelector('[href="#modifier-mot-de-passe"]').addEventListener('click', (e) => {
                e.preventDefault();
                document.querySelector('[data-zone="modifier-mot-de-passe"]').dataset.hidden = false;
                e.target.dataset.hidden = true;
            });

            document.querySelectorAll('input[data-type="password"]').forEach(input => input.addEventListener('focus', (e) => {
                e.target.setAttribute('type', 'password');
                delete e.target.dataset.type;
            }));
            ['change', 'keyup'].forEach(evenement => {
                document.querySelectorAll('[name="compte[pass]"], [name="compte[pass_verif]').forEach(input => input.addEventListener(evenement, (e) => {
                    const pass = document.querySelector('[name="compte[pass]"]')
                    const verif = document.querySelector('[name="compte[pass_verif]"]');
                    if (pass.value) {
                        delete verif.closest('.field').dataset.hidden;
                        if (pass.value && verif.value && pass.value != verif.value) {
                            pass.closest('.field').dataset.error = "Le mot de passe et la vérification ne correspondent pas";
                        } else {
                            delete pass.closest('.field').dataset.error;
                        }
                    } else {
                        verif.closest('.field').dataset.hidden = true;
                    }
                }))
            })

        },
        verifierPseudonymeEtMail() {
            ['name', 'user_email'].forEach(champ => {
                let elements = document.querySelectorAll('form[action="/actions/compte"] [name="compte[' + champ + ']"]');

                ['change', 'keyup'].forEach(event => elements.forEach(element => element.addEventListener(event, (e) => {
                    let element = e.target;
                    console.log(element);
                    attendre(champ, () => {
                        const valeur = element.value;
                        if (element.dataset.valeur != valeur) {
                            const field = element.closest('.field');
                            formFieldMessage(field, 'warning', 'Vérification en cours...');
                            _api.post('api/verifier', { champ, valeur, id: user.id }).then(response => {
                                console.log(response);
                                delete field.dataset.error;
                                delete field.dataset.warning;
                                delete field.dataset.success;
                                let type = 'success';
                                if (response.existe) {
                                    type = 'error';
                                }
                                formFieldMessage(field, type, response.message);
                            });
                        }
                        element.dataset.valeur = valeur;
                    })
                })))
            });
        },

        /**
         * Indique si une personne est connectée (basé sur le cookie)
         * @returns boolean
        */
        isConnected() {
            let c = m.cookie.lire(m.cookie.nom());
            if (c) {
                return c.split(':')[0] > 0;
            }
        },
        /**
         * Indique si une personne est admin
         * @returns boolean
        */
        isAdmin() {
            return this.getCurentUser()?.admin;
        },
        /**
         * Indique si une personne est connectée (basé sur le cookie)
         * @returns boolean
        */
        getCurentUserId() {
            let user = this.getCurentUser();
            if (user) {
                return user.id;
            } else {
                return this.getRandomUserId();
            }
        },
        /**
         * Indique si une personne est connectée (basé sur le cookie)
         * @returns boolean
        */
        getCurentUser() {
            return m.cookie.lire('sofoot-user', true);
        },
        /**
         * AJoute un attribut sur le tag body qui indique si le test de connection du compte est terminé ou nom
         * [data-connection-checked="false"] au démarrage
         * [data-connection-checked="true"] si terminé
         * @returns null
         */
        connectionChecked() {
            document.body.dataset.connectionChecked = true;
        },

        /**
         * AJoute un attribut sur le tag body qui indique si un membre est connecté
         * [data-connected="false"] au démarrage
         * [data-connected="true"] si connecté
         * @returns null
         */
        connectionOk() {
            console.log("connectionOk");
            document.body.dataset.connected = true;
            document.body.dataset.connectedAdmin = m.compte.isAdmin();
            // App.modules.compte.setConnected();
        },
        /**
         * Vérifier si une session est active coté server
         * @param closure callbackOk Action si la session est active
         * @param closure callbackFinally Action dans tous les cas
         * @param closure callbackKo Action si la session n'est pas active
         * @returns 
         */
        verifierSession(callbackOk, callbackFinally, callbackKo = false) {
            return _api.get('/api/session').then(response => {
                if (response.session) {
                    callbackOk()
                } else if (callbackKo) {
                    callbackKo();
                }
            }).always(callbackFinally)

        },
        /**
         * Renseigne l'attribut [data-connected="true"] sur le body si un membre est connecté (après vérification coté serveur)
         * @retuns null 
         */
        setBodyAttrs() {
            this.verifierSession(this.connectionOk, this.connectionChecked)
        },


        /**
         * Retourne un identifiant numérique random si personne n'est connecté
         * @retuns null 
         */
        getRandomUserId() {
            let randomUserId = localStorage.getItem('randomUserId');
            if (!randomUserId) {
                randomUserId = Math.round(Math.random() * 10000000);
                localStorage.setItem('randomUserId', randomUserId)
            }

            return randomUserId;
        },

        /**
         * Actions relatives au compte membre
         */
        start() {
            if (this.isConnected()) {
                this.setBodyAttrs()
            } else {
                this.connectionChecked();
            }

            this.verifierPseudonymeEtMail();
            this.gestionMotDePasse();
            this.activerFormLogin();
        },
    }
})();

