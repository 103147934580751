
/**
 * Module pour gérer les articles de notes
 */
Sofoot.modules.notes = (() => {
	let Cle;
	let Notes;



	/**
	 * Mettre à jour le DOM pour afficher les stats
	 * @param {object} stats 
	 */
	const miseAJourStats = (stats) => {
		for (let slug in stats) {
			if (!slug) continue;
			let joueur = Notes.querySelector(`[data-slug="${slug}"]`)
			if(!joueur) continue;
			let cible = joueur.querySelector('[data-zone="notation"]');
			console.log({ joueur })
			cible.querySelector('[data-zone="nombre-notes"]').innerHTML = stats[slug].count;
			cible.querySelector('[data-zone="note-moyenne"]').innerHTML = stats[slug].moyenne;

		}
	};

	/**
	 * Importer les stats depuis l'api
	 * @param {object} stats 
	 */
	const recupererStatsEtNotes = (stats = false) => {
		console.log('recupererStatsEtNotes', stats)
		/**
		 * Si c'est un article importé, on ne va pas chercher les stats de notes, pour ne pas masquer les stats de notes importées
		 */
		if (!article.legacyContentId) {
			if (stats) {
				miseAJourStats(stats)
			} else {
				/**
				 * Recuperer coté api les stats de notes pour cet article (moyenne, compte)
				 */
				_api.get(`${URL_API}notes/stats/${article.id}`).then(miseAJourStats)
			}
		}
	}

	/**
	 * Sauver une note pour un joueur
	 * @param {int} note la note donnée
	 * @param {string} slug le slug du joueur
	 */
	const noteSauver = (note, slug) => {
		let notes = getNotes();
		notes[slug] = note;
		sessionStorage.setItem(Cle, JSON.stringify(notes));
		let params = { notes, article_id: article.id, user_id: Sofoot.modules.compte.getCurentUserId() }
		_api.post(URL_API + 'notes', params).then(data => {
			console.log(data)

			recupererStatsEtNotes(data)
		});
	}
	/**
	 * Extraire les notes du stockage local
	 * @returns {object} Notes
	 */
	const getNotes = () => {
		let notes = JSON.parse(sessionStorage.getItem(Cle) || '{}');
		return notes;
	}
	/**
	 * Preparation des notes (éxécuté une seule fois)
	 */
	const preparation = () => {
		console.log('preparation');
		recupererStatsEtNotes();
		document.removeEventListener('scroll-end', preparation, false);
	}
	const start = () => {
		if (document.body.dataset.format == 'notes') {
			Cle = 'notes-' + article.id;
			Notes = document.querySelector('[data-zone="content-notes"]');

			console.log('Notes')
			document.addEventListener('noter', (e) => {
				console.log('Noter');
				noteSauver(e.data.note, e.data.slug);
				e.module.setNote(e.data.slug, e.data.note)
				e.module.setEtat(e.module.getNextNote());
			});

			document.addEventListener('scroll-end', preparation);

		}
	}
	return {
		start
	}
})();