
window.Sofoot = window.Sofoot ?? {modules:{}};
window.m = Sofoot.modules;
document.addEventListener("DOMContentLoaded", () => {
    window.site = new Http(URL_SITE);
    window.api = new Http(URL_API);
      
    for (const module in Sofoot.modules) {
        log('Module '+module)
        if('start' in Sofoot.modules[module]) {
            log(' - Module start ')
            Sofoot.modules[module].start();
        }
    }
});