/**
 * Module des outils de developpements
 * 
 */
Sofoot.modules.dev = (() => {

    return {
        toggleAttr(attr, defaut = 'true') {
            let value = document.querySelector('html').getAttribute('data-' + attr);
            console.log(value, defaut)
            value = value ? '' : defaut;
            this.setAttr(attr, value)
            setReglage(attr, value);
        },
        setAttr(attr, value = null) {
            if (value === null) {
                value = getReglage(attr);
            }
            document.querySelector('html').setAttribute('data-' + attr, value);
        },
        start() {
            this.setAttr('grid');
            this.setAttr('user-color-scheme');
        }
    }
})();

