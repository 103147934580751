Sofoot.modules.videos = (() => {
    let videos;
    return {
        loadApi(onload) {
            let tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            tag.onload = () => {
                window.YT.ready(onload)
            };
            let firstScriptTag = document.querySelector('script');
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        },
        start() {
					videos = document.querySelectorAll('.video.lazyYT');
					if (videos.length > 0) {
                this.loadApi(() => {
                    videos.forEach(video => {
                        let youtubeId = video.dataset.youtubeId;
                        let player = new YT.Player(video, {
                            videoId: youtubeId,
                            events: {
                                onReady: (e) => {
                                    let elem = e.target.g;
                                    if(!elem) return;
                                    let ratio = eval(elem.dataset.ratio.replace(':', '/'));
                                    elem.style['aspect-ratio'] = ratio;
                                    elem.style.width = "100%";
                                    elem.style.height = "auto";
                                }
                            }

                        });

                    });

                })
            }
        }
    }

})();



/*

            videos = document.querySelectorAll('.video.lazyYT');
            if (videos) {
                videos.forEach(video => {
                    let youtubeId = video.dataset.youtubeId;
                    video.innerHTML = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${youtubeId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
                })
            }
*/