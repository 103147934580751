Sofoot.modules.site = (() => {
    let temps_avant_refresh = 5;
    let si_refresh = false;
    let tmp_temps_avant_refresh = false;
    return {
        temps_avant_refresh,
        pageOkRefresh() {
            if (!temps_avant_refresh) {
                return false;
            }
            if (document.querySelector('video')) {
                return false;
            }
            if (document.querySelector('iframe')) {
                return false;
            }

            return true;
        },
        check_refresh() {
            clearInterval(si_refresh);
            tmp_temps_avant_refresh = temps_avant_refresh * 60;
            // console.log('refresh', 'Temps avant rechargement de la page: ' + tmp_temps_avant_refresh + ' secondes');
            si_refresh = setInterval(() => {
                setTimeout(() => {
                    document.location.href = '' + document.location.href;
                }, 1000);
            }, temps_avant_refresh * 60 * 1000);
        },
        activerRefreshAuto() {


            try {
                if (this.pageOkRefresh()) {


                    this.check_refresh();
                    document.addEventListener('scroll-end', this.check_refresh);
                    document.addEventListener('keyup', this.check_refresh);
                    document.body.addEventListener('click', this.check_refresh);
                    setInterval(() => {
                        tmp_temps_avant_refresh = tmp_temps_avant_refresh - 10;
                        // console.log('refresh', 'Rechargement dans ' + tmp_temps_avant_refresh + ' secondes');
                    }, 10000);

                }
            } catch (event) {
                // console.log('refresh',event)
            }

        },
        start() {
            // this.activerRefreshAuto()
        }
    }

})();