Sofoot.modules.stats = (() => {
    return {
        update() {
            fetch('/home/stats', { cache: "no-store" }).then(response => response.json()).then(stats => {
                for (const stat of stats) {
                    const articles = document.querySelectorAll(`[data-id="${stat.id}"]`);
                    if (!articles) return;
                    for (const article of articles) {
                        const likes = article.querySelector('.article-actions [data-stat="likes"]');
                        if (likes)
                            likes.dataset.valueSvg = stat.likes;
                        const commentaires = article.querySelector('.article-actions [data-stat="commentaires"]');
                        if (commentaires)
                            commentaires.dataset.valueSvg = stat.commentaires;

                        const reactions = article.querySelector('[data-zone="reactions"] [href="#commentaires"] span, [data-zone="reactions"] > span');
                        if (reactions)
                            reactions.innerHTML = stat.commentaires;

                        const likesArticle = article.querySelector('[data-zone="likes"] span');
                        if (likesArticle)
                            likesArticle.innerHTML = stat.likes;
                    }
                }
            })
        },
        start() {
            this.update()
        }
    }
})();