Sofoot.modules.analytics = (() => {
    return {
        autoHit() {
            this.hit({
                url : getCanonical(),
                title : window.title
            })
        },
        hit(params) {

            // matomo
            try {
                _paq.push(['setCustomUrl', params.url]);
                _paq.push(['setDocumentTitle', params.titre]);
                _paq.push(['trackPageView']);
            } catch (e) {
                console.log('matomo hit',params);
            }
        }
    }
})();