Sofoot.modules.live = (() => {
    let live;
    return {
        activerLive() {
            document.addEventListener('live-recharger', (e) => {
                // if(urgence) return document.location.reload();
                try {
                    if (live.dataset.chargement) return;
                    live.dataset.chargement = true;
                    chargerUrl(getCanonical(), (dom) => {
                        delete live.dataset.chargement;
                        let equipes = dom.querySelector('[data-zone="live"] [data-zone="equipes"]');

                        live.querySelector('[data-zone="equipes"]').remove();
                        live.prepend(equipes);

                        let newMessages = dom.querySelectorAll('[data-tab="live"] [data-zone="messages"] [data-zone="message-live"]');
                        let newLive = dom.querySelector('[data-tab="live"] [data-zone="messages"]');
                        let oldLive = document.querySelector('[data-tab="live"] [data-zone="messages"]');
                        oldLive.innerHTML = newLive.innerHTML;
                        Sofoot.modules.twitter.parseTweets(oldLive);
                        App.modules.live.activerLive();
                        setTimeout(e.module.finChargementLive, 2000);

                        document.querySelector('.tirs-au-but')?.remove();
                        if (dom.querySelector('.tirs-au-but')) {
                            document.querySelector('[data-tab="live"]').prepend(dom.querySelector('.tirs-au-but'));
                        }
                        return;

                        let delay = 0;
                        let messages = Array.from(newMessages).reverse();
                        console.log('Live', messages);
                        let total = messages.length;
                        messages.forEach(message => {
                            let id = message.dataset.id;
                            let ancien = oldLive.querySelector(`[data-id="${id}"]`);
                            if (ancien) {
                                console.log('Live', 'ancien', message);
                                total--;
                                if (total == 0) {
                                    setTimeout(e.module.finChargementLive, 2000);
                                }
                                // ancien.replaceWith(message);
                            } else {
                                setTimeout(() => {
                                    console.log('Live', 'nouveau', message);
                                    message.classList.add('nouveau');
                                    oldLive.prepend(message);
                                    Sofoot.modules.twitter.parseTweets(message);
                                    setTimeout(() => {
                                        message.classList.remove('nouveau');
                                        total--;
                                        if (total == 0) {
                                            e.module.finChargementLive();
                                        }
                                    }, 1000);
                                }, delay * 1000)
                                delay++;
                            }
                            // if (!oldLive.querySelector(`[data-id="${id}"]`)) {
                            //     setTimeout(() => {
                            //         oldLive.prepend(message);
                            //     },delay*1000)
                            //     delay++;
                            // }
                        })
                    }, { cache: "reload" });
                } catch (erreur) {
                    console.log(erreur);
                    setTimeout(e.module.finChargementLive, 2000);
                }
            });
            document.addEventListener('live-afficher-messages', (e) => {
                let autres = getComment(live.querySelector('#autres-messages'));
                if (autres) {
                    console.log(autres)
                    live.querySelector('[data-zone="messages"]').innerHTML += autres;
                    live.querySelector('#autres-messages').remove();
                }
                e.module.afficherTousLesMessages();
            })
        },
        liveHome() {
            document.querySelectorAll('[data-zone="une"] article.article > a[href*="en-direct"]').forEach(a => {
                console.log(a.href);
                const article = a.closest('article');
                if (article.querySelector('h2').innerText.toLowerCase().includes('revivez')) return;
                chargerUrl(a.href, (dom) => {
                    const titre = dom.querySelector('h1[data-titre-home]')?.dataset.titreHome;
                    console.log({ titre });
                    if (titre) {
                        article.querySelector('h2').innerHTML = titre
                    }
                    const desc = dom.querySelector('[data-extrait]')?.dataset.extrait;
                    console.log({ desc })
                    if (desc) {
                        article.querySelector('p').innerHTML = desc

                    }

                });
            })
            setTimeout(this.liveHome, 30000)
        },
        start() {
            this.liveHome()
            live = document.querySelector('[data-zone="live"]');
            if (live) {
                this.activerLive()
            }
        }
    }

})();