Sofoot.modules.contenus = (() => {
    let date = new Date().toLocaleDateString();
    return {
        getLectures() {
            return JSON.parse(localStorage.getItem('lectures ' + date) || '{}');
        },
        getLecture(url) {
            let lectures = this.getLectures();
            return lectures.urls && lectures.urls[url];
        },
        setLectures(lectures) {
            return localStorage.setItem('lectures ' + date, JSON.stringify(lectures));
        },
        setLecture(url, article) {
            let lectures = this.getLectures();
            lectures.urls = lectures.urls || {};
            lectures.urls[url] = true;
            lectures.today = lectures ? (lectures.today || {}) : {};

            if (new Date(article.date).toLocaleDateString() == date) {
                lectures.today[article.type_contenu] = lectures.today[article.type_contenu] || {};
                lectures.today[article.type_contenu][url] = true;
            }
            this.setLectures(lectures);
        },
        activerLecturesContenus() {
            if (typeof article == 'undefined') return;

            console.log('activerLecturesContenus')
            document.addEventListener('scroll-end', () => {
                let url = getCanonical();
                if (!url) return;
                this.setLecture(url, article);

                this.miseAJourLecturesContenus();
            }, { once: true });
        },
        miseAJourLecturesContenus() {
            try {
                let zone = document.querySelector('[data-zone="nb-actus"]');
                if (!zone) return;
                let total = zone.innerHTML;
                let lectures = this.getLectures();
                total = Math.max(total - Object.keys(lectures.today.breve || {}).length, 0);
                zone.innerHTML = total || '';
            } catch (e) {
                // console.log('miseAJourLecturesContenus', e);
            }
        },

        /**
         * Mise à jour des breves en colonne de droite
         * @returns null
         */
        miseAJourBreves() {
            // ne pas le faire en home
            if (document.querySelector('[data-page="home"]')) return;

            // ne pas le faire si la page ne contient pas de breves
            let groupe = document.querySelector('.groupe-de-breves [data-mode-articles="breve"]');
            if (!groupe) return;
            const zone = groupe.closest('[data-zone="breves"]');
            if (zone?.dataset.lazy == 'false') return;

            let anciennes = groupe.querySelectorAll('.article');
            let nb = anciennes.length;
            if (!nb) return;

            // charger la home en asynchrone puis récupérer les breves
            chargerUrl('/', (dom) => {
                let sondage = dom.querySelector('.sondage');
                if (sondage && !zone.querySelector('.sondage')) {
                    zone.querySelectorAll('.sondage').forEach(item => item.remove());
                    zone.prepend(sondage);
                    App.modules.sondages.set()
                    Sofoot.modules.sondages.set()
                }
                let breves = dom.querySelectorAll('[data-mode-articles="breve"] .article:nth-child(-n+' + nb + ')');

                // console.log('breves', breves);
                anciennes.forEach(breve => breve.remove());
                // p2.insertBefore(n1, p2.children[i2]);
                groupe.prepend(breves[1]);
                groupe.prepend(breves[0]);

                breves.forEach((breve, idx) => {
                    if (idx > 1) {
                        groupe.append(breve);
                    }
                });
            }, { cache: 'reload' });

        },
        homeTournante() {
            if (Sofoot.modules.compte.isAdmin()) return;
            // if (!document.location.hash.includes('rotation')) return;
            if (!document.querySelector('[data-page="home"]')) return;
            let articles = document.querySelectorAll('[data-zone="une"] .article:not([data-mode="breve"])');
            let premier = articles[0];
            if (premier.innerHTML.includes('en-direct')) return;
            if (premier.innerHTML.includes('multiplex')) return;
            if (premier.innerHTML.includes('le-direct-des-jeux-olympiques')) return;

            if (premier.closest('[data-zone="mosaique"]')) return;

            let url_premier = premier.querySelector(':scope > a').href;
            if (this.getLecture(url_premier)) {
                for (let article of articles) {
                    if (article.closest('[data-zone="mosaique"]')) continue;
                    let url = article.querySelector(':scope > a').href;
                    if (url == url_premier) continue;
                    if (!this.getLecture(url)) {
                        article.dataset.mode = 'auto';
                        return swapNodes(premier, article);
                    }

                };
            }
        },
        start() {
            this.miseAJourLecturesContenus();
            this.activerLecturesContenus();
            this.miseAJourBreves();
            this.homeTournante();
        }
    }

})();
