/**
 * Module pour gérer les raccourcis claviers
 * Les raccourcis sont définis dans le tableau `shortcuts`
 * Tous les raccourcis commencent par $$
 * 
 * @example 
 *         {
 *            name: 'mon raccourcis', // nom du raccourcis
 *            sequence: ['e'], // séquence de touches (après les deux $ obligatoires)
 *            target: true, // option permettant d'effectuer l'action du raccourcis clavier dans une nouvelle fenetre en ajoutant ! après $
 *                          // Exemple : $-e pour éditer, $-!-e pour éditer dans une nouvelle fenetre
 *            action: (target = '_self') => {
 *              // code de la fonction exécutée (le parametre target est facultatif)
 *            }
 *        },
 * 
 */
Sofoot.modules.shortcuts = (() => {
    let url_page = getCanonical();
    const shortcuts = [
        {
            name: 'Admin',
            sequence: ['admin'],
            target: true,
            action: (target = '_self') => {
                Sofoot.modules.shortcuts.open('/admin', target);
            }
        },
        {
            name: 'Sogest',
            sequence: ['so'],
            action: () => {
                if (article) {
                    window.open('https://sogest.sopress.net/index.php?w=saisie_activite&support_id=8&id_article=' + article.id, '_blank');
                }
            }
        },

        {
            name: 'Bug',
            sequence: ['bug'],
            action: () => {
                let titre = 'Bug sur la page ' + document.title;
                let content = `${document.location.href}`;
                window.open('https://www.notion.so/sopress/Bienvenue-5338389ff365467ca80b607b4de9659e', '_blank');
                // window.open('https://gitlab.com/sofoot.com/new-sofoot.com/front/-/issues/new?issuable_template=bug&issue[title]=' + encodeURI(titre) + '&issue[description]=' + encodeURI(content), '_blank');
            }
        },
        {
            name: 'Stats',
            sequence: ['stats'],
            action: () => {
                let url = new URL(document.querySelector('[rel="canonical"]').getAttribute('href'));
                window.open('https://app.usefathom.com/share/flobiopx/sofoot.com?filters[0][operator]=is&filters[0][property]=Path&filters[0][value]= ' + encodeURIComponent(url.pathname) + '&page=1&range=last_7_days&sort=visitors%3Adesc', '_blank');
            }
        },
        {
            name: 'Infra',
            sequence: ['i'],
            action: () => {
                window.open('https://status.sofoot.com/', '_blank');
            }
        },
        {
            name: 'Réactions',
            sequence: ['comms'],
            action: () => {
                if (article) {
                    fetch(`/reactions/comments/${article.id}?safe`).then(response => {
                        alert('Nombre de réactions mis à jour');
                    });
                }
            }
        },
        {
            name: 'Editer',
            sequence: ['e'],
            target: true,
            // action: (target = '_self') => {
            //     let url;
            //     if ($('body').data('file') == 'article') {
            //         /**
            //          * Editer un article
            //          */
            //         url = `${URL_BACKOFFICE}wp-admin/post.php?post=${article.pid ?? article.id}&action=edit`;
            //     } else if ($('body').data('page') == 'home') {
            //         /**
            //          * Editer la page d'accueil
            //          */
            //         url = `${URL_BACKOFFICE}wp-admin/admin.php?page=acf-options-home`;
            //     } else if ($('body').data('file') == 'categorie') {
            //         /**
            //          * Editer une catégorie
            //          */
            //         url = `${URL_BACKOFFICE}wp-admin/term.php?taxonomy=${categorie.slug}&tag_ID=${terme.id}`;
            //     } else if (typeof terme != 'undefined') {
            //         url = `${URL_BACKOFFICE}wp-admin/term.php?taxonomy=${terme.type.replace('tags','post_tag')}&tag_ID=${terme.id}`;
            //     } else if (typeof joueur != 'undefined') {
            //         url = `${URL_BACKOFFICE}wp-admin/term.php?taxonomy=joueurs&tag_ID=${joueur.term_id}`;
            //     } else if (typeof equipe != 'undefined') {
            //         url = `${URL_BACKOFFICE}wp-admin/term.php?taxonomy=equipes&tag_ID=${equipe.term_id}`;
            //     } else if (typeof definition != 'undefined') {
            //         url = `${URL_BACKOFFICE}wp-admin/term.php?taxonomy=${definition.type}&tag_ID=${definition.id}`;
            //     }
            //     Sofoot.modules.shortcuts.open(url, target)

            // }
            action: (target = '_self') => {
                let url;
                if (document.body.dataset.file === 'article') {
                    /**
                     * Editer un article
                     */
                    url = `${URL_BACKOFFICE}wp-admin/post.php?post=${article.pid ?? article.id}&action=edit`;
                } else if (document.body.dataset.page === 'home') {
                    /**
                     * Editer la page d'accueil
                     */
                    url = `${URL_BACKOFFICE}wp-admin/admin.php?page=acf-options-home`;
                } else if (document.body.dataset.file === 'categorie') {
                    /**
                     * Editer une catégorie
                     */
                    url = `${URL_BACKOFFICE}wp-admin/term.php?taxonomy=${categorie.slug}&tag_ID=${terme.id}`;
                } else if (typeof terme !== 'undefined') {
                    url = `${URL_BACKOFFICE}wp-admin/term.php?taxonomy=${terme.type.replace('tags', 'post_tag')}&tag_ID=${terme.id}`;
                } else if (typeof joueur !== 'undefined') {
                    url = `${URL_BACKOFFICE}wp-admin/term.php?taxonomy=joueurs&tag_ID=${joueur.term_id}`;
                } else if (typeof equipe !== 'undefined') {
                    url = `${URL_BACKOFFICE}wp-admin/term.php?taxonomy=equipes&tag_ID=${equipe.term_id}`;
                } else if (typeof definition !== 'undefined') {
                    url = `${URL_BACKOFFICE}wp-admin/term.php?taxonomy=${definition.type}&tag_ID=${definition.id}`;
                }
                Sofoot.modules.shortcuts.open(url, target);
            }
            // action: (target = '_self') => {
            //     let url;
            //     if (document.body.dataset.file === 'article') {
            //         /**
            //          * Editer un article
            //          */
            //         url = `${URL_BACKOFFICE}wp-admin/post.php?post=${article.pid ?? article.id}&action=edit`;
            //     } else if (document.body.dataset.page === 'home') {
            //         /**
            //          * Editer la page d'accueil
            //          */
            //         url = `${URL_BACKOFFICE}wp-admin/admin.php?page=acf-options-home`;
            //     } else if (document.body.dataset.file === 'categorie') {
            //         /**
            //          * Editer une catégorie
            //          */
            //         url = `${URL_BACKOFFICE}wp-admin/term.php?taxonomy=${categorie.slug}&tag_ID=${terme.id}`;
            //     } else if (typeof terme !== 'undefined') {
            //         url = `${URL_BACKOFFICE}wp-admin/term.php?taxonomy=${terme.type.replace('tags', 'post_tag')}&tag_ID=${terme.id}`;
            //     } else if (typeof joueur !== 'undefined') {
            //         url = `${URL_BACKOFFICE}wp-admin/term.php?taxonomy=joueurs&tag_ID=${joueur.term_id}`;
            //     } else if (typeof equipe !== 'undefined') {
            //         url = `${URL_BACKOFFICE}wp-admin/term.php?taxonomy=equipes&tag_ID=${equipe.term_id}`;
            //     } else if (typeof definition !== 'undefined') {
            //         url = `${URL_BACKOFFICE}wp-admin/term.php?taxonomy=${definition.type}&tag_ID=${definition.id}`;
            //     }
            //     Sofoot.modules.shortcuts.open(url, target);
            // }
        },
        {
            name: 'Voir la source API / Debug API',
            sequence: ['w'],
            target: true,
            // action: (target = '_self') => {
            //     let url;
            //     if ('api' in donnee) {
            //         url = donnee.api
            //     } else if ($('body').data('file') == 'article') {
            //         url = article.api
            //     } else {
            //         url = document.location.href.replace('debug-api', '') + (document.location.href.includes('?') ? '&' : '?') + 'debug-api';
            //         target = '_self';
            //     }
            //     Sofoot.modules.shortcuts.open(url, target)
            // }
            action: (target = '_self') => {
                let url;
                if ('api' in donnee) {
                    url = donnee.api;
                } else if (document.body.dataset.file === 'article') {
                    url = article.api;
                } else {
                    url = `${document.location.href.replace('debug-api', '')}${document.location.href.includes('?') ? '&' : '?'}debug-api`;
                    target = '_self';
                }
                Sofoot.modules.shortcuts.open(url, target);
            }
            // action: (target = '_self') => {
            //     let url;
            //     if ('api' in donnee) {
            //         url = donnee.api;
            //     } else if (document.body.dataset.file === 'article') {
            //         url = article.api;
            //     } else {
            //         url = `${document.location.href.replace('debug-api', '')}${document.location.href.includes('?') ? '&' : '?'}debug-api`;
            //         target = '_self';
            //     }
            //     Sofoot.modules.shortcuts.open(url, target);
            // }
        },
        {
            name: 'Afficher / Masquer la grille',
            sequence: ['g'],
            action: () => Sofoot.modules.dev.toggleAttr('grid')
        },
        {
            name: 'Debug des api',
            sequence: ['api'],
            action: () => {
                if (document.location.href.includes('debug-api')) {
                    document.location.reload(true);
                } else {
                    let url = document.location.href;
                    window.open(url + (url.includes('?') ? '&' : '?') + 'debug-api=true', '_self')
                }

            }
        },
        {
            name: 'Debug des pubs',
            sequence: ['pub'],
            action: () => {
                let url = url_page;
                if (article) {
                    url = '/' + article.type_contenu + 's/' + article.id
                }
                window.open(url + '?debug-pubs', '_self')

            }
        },
        {
            name: 'Activer / Désactiver le dark mode',
            sequence: ['m'],
            action: () => App.modules.darkMode.toggleScheme()
        },
        {
            name: 'Reconstruire la page & vider tous les transients',
            sequence: ['r'],
            action: () => {
                let url = URL_SITE + 'rebuild.php?' + Math.random();

                if (typeof article != 'undefined') {
                    url += '&id=' + article.id;
                } else {
                    url_page = document.location.href.split('?')[0];
                    url += '&url=' + encodeURIComponent(url_page);
                }
                url += '&back=' + encodeURIComponent(url_page + '?preview')
                console.log(url);
                document.location.href = url;
            }
        },
        {
            name: 'Afficher la page dynamique d\'un article',
            sequence: ['d'],
            action: () => {
                let url = URL_SITE + 'rebuild.php?url=' + encodeURIComponent(url_page);

                if (article) {
                    url += '&back=/' + article.type_contenu + 's/' + article.id
                }
                document.location.href = url;
            }
        },
        {
            name: "Poster un nouvel article",
            sequence: ['n'],
            target: true,
            action: (target = 'popup') => {
                let url = URL_BACKOFFICE + '/wp-admin/post-new.php';
                Sofoot.modules.shortcuts.open(url, target)
            }
        }
    ];


    let stream = [];

    return {
        open(url, target) {
            if (url) {
                if (target == 'popup') {
                    popupCenter({ url })
                } else {
                    window.open(url, target);
                }
            }

        },
        getStream() {
            ['hashchange', 'load'].forEach(evenement => {
                window.addEventListener(evenement, () => {
                    let hash = document.location.hash.replace('#', '');
                    if (!hash.includes('$$')) return;
                    this.checkStream(hash.split('').join('-'));
                });
            });
            document.addEventListener("keydown", (e) => {
                const key = e.key;
                const tag = e.target.tagName.toLowerCase();
                let clear = false;
                if (tag == 'input' || tag == 'textarea') {
                    clear = true;
                }
                if (key == 'Backspace' || key == 'Escape' || key == 'Delete') {
                    clear = true;
                }
                let unique = false;
                if (key == 'Control' || key == 'Shift' || key == 'Alt') {
                    unique = true;
                }
                if (clear) {
                    this.clearStream();
                } else {
                    attendre('stream', this.clearStream, 2000);

                    if (!unique || !stream.includes(key)) {
                        stream.push(key);
                        this.checkStream();
                    }
                }
            });
        },
        checkStream(streamStr = false) {
            streamStr = streamStr ? streamStr : stream.join('-');
            shortcuts.forEach(shortcut => {
                let sequenceStr = '$-$-' + shortcut.sequence.map(element => element.split('').join('-')).join('-');
                if (streamStr.endsWith(sequenceStr)) {
                    shortcut.action();
                    this.clearStream();
                } else if (shortcut.target && streamStr.includes('$-$-!-')) {
                    sequenceStr = sequenceStr.replace('$-$-', '$-$-!-');
                    if (streamStr.endsWith(sequenceStr)) {
                        shortcut.action('_blank');
                        this.clearStream();
                    }
                } else if (shortcut.target && streamStr.includes('$-$-+-')) {
                    sequenceStr = sequenceStr.replace('$-$-', '$-$-+-');
                    if (streamStr.endsWith(sequenceStr)) {
                        shortcut.action('popup');
                        this.clearStream();
                    }
                }
            })
        },
        clearStream() {
            stream = [];
        },
        start() {
            this.getStream();
        }
    }
})();

