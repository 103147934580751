Sofoot.modules.forum = (() => {
    let forum, messages;
    return {
        reponseLocale(message) {
            let txt = message.querySelector('div>div').innerHTML;
            let nom = message.querySelector('aside>div>span')?.innerText;
            let div = document.createElement('div');
            div.innerHTML = txt;
            div.querySelectorAll('blockquote, .citation, br, a').forEach(element => element.remove());
            let texte = div.innerHTML.trim();
            if (nom && texte) {
                texte = `[quote][b]${nom} a écrit[/b]\n${texte}[/quote]\n`;
            }
            return texte;
        },
        activerActions() {
            document.addEventListener('effacer-message-forum', e => {
                fetch('/api/forum/reponse/' + e.data.messageId, {
                    method: 'DELETE'
                }).then(response => {
                    document.querySelector('[data-zone="reponse"][data-id="' + e.data.messageId + '"]').remove();
                })
            });
        },
        activerReponse() {
            document.addEventListener('rediger-message-forum', e => {
                let message = document.querySelector(`article[data-id="${e.data.messageId}"]`);
                message.scrollIntoView();
                _api.get('/api/forum/reponse/' + message.dataset.id).then(message => {
                    let texte = message?.texte ? `[quote][b]${message.name} a écrit[/b]\n${message.texte}[/quote]\n` : '';
                    e.module.ouvrirRedigerReponse(texte);
                })
                // if (message.dataset.editable) {
                // } else {
                //     e.module.ouvrirRedigerReponse(this.reponseLocale(message));
                // }
            });

            document.addEventListener('valider-rediger-message-forum', e => {
                _api.post('/api/forum/reponse', e.data).done(response => {
                    e.module.fermerRedigerReponse();
                    document.location.href = response.url;
                    if (document.location.href.split('#')[0] == response.url.split('#')[0]) {
                        document.location.reload();
                    }
                }).fail(error => {
                    console.log(error)
                })
            })

            window.addEventListener('hashchange', (e) => {
                let hash = document.location.hash.replace('#');
                console.log('hashchange', hash)
                let ancre = document.querySelector(`a[name="${hash}"]`);
                if (ancre) {
                    ancre.scrollIntoView();
                }
            })

        },

        start() {
            forum = document.querySelector('[data-zone="forum"]');
            if (forum) {
                messages = forum.querySelector('[data-zone="messages"]');
                if (messages) {
                    this.activerReponse()
                    this.activerActions()
                }
            }
        }
    }

})();