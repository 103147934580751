const Http = (function () {
    class Http {
        constructor(baseUrl) {
            this.baseUrl = baseUrl;
        }

        doFetch(url, options) {
            const headers = {
                'Content-Type': 'application/json'
            };
            if (options && options.body) {
                options.body = JSON.stringify(options.body);
            }
            return fetch(url, {
                headers,
                ...options
            }).then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json();
            });
        }

        get(uri, params = null, options = null) {

            const cache = options?.cache || params?.cache || true;

            const url = new URL(`${this.baseUrl}${uri}`);
            if (params) {
                Object.keys(params).forEach(key => {
                    if(key == 'cache') return;
                    url.searchParams.append(key, params[key])
                });
            }
            return this.doFetch(url, { method: 'GET', cache: cache ? 'default' : 'no-store' });
        }

        post(uri, data = null) {
            return this.doFetch(`${this.baseUrl}${uri}`, {
                method: 'POST',
                body: data,
            });
        }

        put(uri, data) {
            return this.doFetch(`${this.baseUrl}${uri}`, {
                method: 'PUT',
                body: data
            });
        }

        patch(uri, data) {
            return this.doFetch(`${this.baseUrl}${uri}`, {
                method: 'PATCH',
                body: data
            });
        }

        delete(uri) {
            return this.doFetch(`${this.baseUrl}${uri}`, { method: 'DELETE' });
        }
    }

    return Http;
})();